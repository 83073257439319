import React, { useEffect } from 'react'
import { authentication } from '../lib'

export const AppMenuMountDiv = () => {
  const token = authentication.getToken()

  useEffect(() => {
    if (token) {
      // @ts-ignore
      window.tomraMenu.mount(token)
    }
  }, [token])

  return <div id="menu-root" data-testid="app-menu" />
}
